import logo from './logo.svg';
import './App.css';
import './components/chores/choreChart'
import ChoreChart from './components/chores/choreChart';

function App() {
  return (
    <div className="App">
     <ChoreChart />
    </div>
  );
}

export default App;
